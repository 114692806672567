// SELECT-INPUTS

import { Role } from '@system/acl';

export const SELECT_OPTIONS_ADMIN = [
  { value: Role.Operacional, label: 'Operacional' },
  { value: Role.Financeiro, label: 'Financeiro' },
  { value: Role.ProdutosInternos, label: 'Produtos' },
  { value: Role.Comprador, label: 'Comprador' },
  { value: Role.Gestao, label: 'Gestão' },
  { value: Role.Administrador, label: 'Administrador' },
  { value: Role.CompradorAdministrador, label: 'Comprador Administrador' },
];

export const SELECT_OPTIONS_BUYER_ADMIN = [
  { value: Role.Comprador, label: 'Comprador' },
  { value: Role.CompradorAdministrador, label: 'Comprador Administrador' },
];

export const SELECT_OPTIONS_STATUS = [
  { value: 1, label: 'Sim' },
  { value: 0, label: 'Não' },
];
