import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as bcrypt from 'bcryptjs';
import dayjs from 'dayjs';
import { mask, unMask } from 'remask';
import * as yup from 'yup';
import BreadCrumbs from '@components/breadcrumbs';
import { ButtonV2 } from '@components/new-components/button-v2';
import { Form } from '@components/new-components/form';
import { InputV2 } from '@components/new-components/input-v2';
import { SelectV2 } from '@components/new-components/select-v2';
import {
  SELECT_OPTIONS_ADMIN,
  SELECT_OPTIONS_BUYER_ADMIN,
} from '@data/roles.data';
import { yupResolver } from '@hookform/resolvers/yup';
import api from '@services/api';
import { useAuthentication } from '@stores/authentication';
import { Role } from '@system/acl';
import { SELECT_OPTIONS_STATUS } from '@system/utils/adminGenerics';
import { toaster } from '@system/utils/toaster';
import { validarCPF } from '@system/validators/cpfValidator';
import LoadingAnimation from '../components/LoadingAnimation';
import { PagePath } from '../pages-config';

const schema = yup.object().shape({
  firstName: yup.string().required('Nome é obrigatório'),
  familyName: yup.string().required('Sobrenome é obrigatório'),
  cpf: yup
    .string()
    .required('CPF é obrigatório')
    .test('cpf-valido', 'CPF inválido', (value) => validarCPF(value)),
  email: yup.string().email('Email inválido').required('Email é obrigatório'),
  pass: yup
    .string()
    .min(6, 'A senha deve ter pelo menos 6 caracteres')
    .required('Senha é obrigatória'),
  status: yup.string().required('Perfil é obrigatório'),
  company: yup.string().required('Empresa é obrigatória'),
  userActive: yup.string().required('Ativo é obrigatório'),
});

export default function AdminUsuariosNovo() {
  const [loading, setLoading] = useState(true);
  const [listAgencia, setListAgencia] = useState([]);
  const navigate = useNavigate();
  const { session } = useAuthentication();

  async function listaAgenciaCliente() {
    let query =
      'SELECT * FROM `vpcharter_contratante` ORDER BY `vpcharter_contratante`.`razaosocial` ASC ';
    let fData = new FormData();
    fData.append('query', query);
    try {
      const { data } = await api.post('/query', fData);
      if (data.length > 0) {
        setListAgencia(data);
        setLoading(false);
      }
    } catch (error) {
      alert(error);
    }
  }

  useEffect(() => {
    listaAgenciaCliente();
  }, []);

  const handleGoBack = () => navigate(PagePath.AdminUsuarios);

  const SELECT_OPTIONS_COMPANIES = listAgencia
    ?.filter((data) => {
      return (
        session.role === Role.Administrador ||
        (session.role === Role.CompradorAdministrador &&
          data.nome_fantasia === session.agency.nomeFantasia)
      );
    })
    .map((data) => ({
      value: data.id,
      label: data.nome_fantasia,
    }));

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      userActive: 1,
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  async function onSubmit(payload) {
    const queryFindUserByEmail = `
        SELECT * FROM vpcharter_users 
        WHERE email = '${payload.email}'
    `;
    let formDataFindUserByEmail = new FormData();
    formDataFindUserByEmail.append('query', queryFindUserByEmail);
    try {
      const { data } = await api.post('/query', formDataFindUserByEmail);
      if (data.length > 0) {
        toaster('error', 'E-mail ja cadastrado!');
        return;
      }
      const hash = await bcrypt.hash(payload['pass'], 8);
      payload['pass'] = hash;

      const queryInsertUser = `
        INSERT INTO vpcharter_users (
            name, 
            familyName, 
            firstName, 
            status,
            pass, 
            email, 
            dateRegister, 
            userActive, 
            agenciaCliente,
            cpf
        ) VALUES (
            '${payload.firstName}', 
            '${payload.familyName}', 
            '${payload.firstName}', 
            '${payload.status}', 
            '${payload.pass}', 
            '${payload.email}', 
            '${dayjs().format('YYYY-MM-DD HH:mm:ss')}', 
            '${payload.userActive}', 
            '${payload.company}',
            '${payload.cpf}'
        );
    `;
      const formDataInsertUser = new FormData();
      formDataInsertUser.append('query', queryInsertUser);

      await api.post('/query', formDataInsertUser);
      toaster('success', 'Usuário criado com sucesso!');
      setTimeout(() => {
        navigate(PagePath.AdminUsuarios);
      }, 3000);
    } catch (error) {
      toaster('error', 'Não foi possível criar o usuário');
    }
  }

  const formatDocument = (e) => {
    const value = e.target.value;
    let clearMask = unMask(value);
    e.target.value = mask(clearMask, ['999.999.999-99']);
  };

  return (
    <div>
      {loading && <LoadingAnimation />}
      <div className="flex w-full flex-col gap-8 pb-3">
        <BreadCrumbs
          paths={[
            { label: 'Home', link: PagePath.Home },
            { label: 'Configurações', link: PagePath.Admin },
            { label: 'Usuários', link: PagePath.AdminUsuarios },
            { label: 'Novo usuário', link: PagePath.AdminUsuariosNovo },
          ]}
        />
        <h1 className="m-0 text-heading-1 text-primary">Saídas</h1>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-3 grid-rows-3 gap-4 rounded-md bg-white p-4">
          <Form.Group>
            <Form.Label>Nome</Form.Label>
            <InputV2
              placeholder="Digite o nome"
              {...register('firstName')}
              required
            />
            <Form.Message error={errors?.firstName} />
          </Form.Group>

          <Form.Group>
            <Form.Label>Sobrenome</Form.Label>
            <InputV2
              placeholder="Digite o sobrenome"
              {...register('familyName')}
              required
            />
            <Form.Message error={errors?.familyName} />
          </Form.Group>

          <Form.Group>
            <Form.Label>CPF</Form.Label>
            <InputV2
              placeholder="Digite o CPF"
              {...register('cpf')}
              onChange={formatDocument}
              required
            />
            <Form.Message error={errors?.cpf} />
          </Form.Group>

          <Form.Group>
            <Form.Label>E-mail</Form.Label>
            <InputV2
              placeholder="Digite o e-mail"
              {...register('email')}
              required
            />
            <Form.Message error={errors?.email} />
          </Form.Group>

          <Form.Group>
            <Form.Label>Senha</Form.Label>
            <InputV2
              type="password"
              placeholder="Digite a senha"
              {...register('pass')}
              required
            />
            <Form.Message error={errors?.pass} />
          </Form.Group>

          <Form.Group>
            <Form.Label>Perfil</Form.Label>
            <Controller
              control={control}
              name="status"
              render={({ field }) => (
                <SelectV2
                  options={
                    session.role === Role.Administrador
                      ? SELECT_OPTIONS_ADMIN
                      : SELECT_OPTIONS_BUYER_ADMIN
                  }
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Form.Message error={errors?.status} />
          </Form.Group>

          <div className="col-span-2">
            <Form.Group>
              <Form.Label>Empresa</Form.Label>
              <Controller
                control={control}
                name="company"
                render={({ field }) => (
                  <SelectV2
                    options={SELECT_OPTIONS_COMPANIES}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <Form.Message error={errors.company} />
            </Form.Group>
          </div>
          <div className="col-start-3">
            <Form.Group>
              <Form.Label>Ativo</Form.Label>
              <Controller
                control={control}
                name="userActive"
                render={({ field }) => (
                  <SelectV2
                    options={SELECT_OPTIONS_STATUS}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <Form.Message error={errors.userActive} />
            </Form.Group>
          </div>
        </div>

        <div className="mt-4 flex w-full justify-between">
          <ButtonV2
            ghost
            size="large"
            onClick={handleGoBack}
            className="w-[200px]"
          >
            <ButtonV2.Text>Voltar</ButtonV2.Text>
          </ButtonV2>
          <ButtonV2 size="large" className="w-[200px]" type="submit">
            <ButtonV2.Text>Salvar</ButtonV2.Text>
          </ButtonV2>
        </div>
      </Form>
    </div>
  );
}
