import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import CreditAllocationInfo from '@components/credit-allocation-info';
import FlightDetails from '@components/flight-details';
import { FlightPriceDetail } from '@components/flight-price-detail';
import LoadingAnimation from '@components/LoadingAnimation';

import { ModalReservationCreatePassenger } from '@components/modal/modal-reservation-create-passenger';
import { ModalReservationConfirm } from '@components/modal/ModalReservationConfirm';
import { useBookings } from '@stores/bookings';
import { PaymentMethod } from '@system/enums';
import { PagePath } from '../pages-config';

export default function AereoReservaEtapas() {
  const navigate = useNavigate();
  const { state: params, pathname } = useLocation();
  const { loading, travelers, methods, booking, modal } = useBookings();
  const steps = {
    [PagePath.AereoReservasCheckout]: 'checkout',
    [PagePath.AereoReservasPagamento]: 'payment',
    [PagePath.AereoReservasConfirmar]: 'confirmation',
  };

  const currentStep = steps[pathname] ?? 'checkout';
  const handleGoBack = () => {
    methods.reset();
    navigate(PagePath.AereoReservas, { replace: true });
  };

  const useAllotments = true;
  const useCredits =
    currentStep === 'checkout'
      ? true
      : booking?.paymentMethod === PaymentMethod.Faturamento;

  useEffect(() => {
    if (
      travelers?.length === 0 &&
      pathname !== PagePath.AereoReservasCheckout
    ) {
      navigate(PagePath.AereoReservasCheckout, {
        replace: true,
        state: params,
      });
    }
    methods.getFlightDetails(params.charterId);
  }, []);

  return (
    <>
      {loading && <LoadingAnimation />}
      <div className="  box-border    flex w-full flex-row items-center justify-between bg-white p-4">
        <a
          href="#"
          onClick={handleGoBack}
          className="box-border  w-auto whitespace-nowrap  text-left"
        >
          Voltar para listagem de reservas
        </a>
        <div className="flex w-full  items-center justify-center">
          {/* Stepper */}
          <ul className="flex flex-row items-center justify-center gap-x-16">
            {/* Item */}
            <li className=" !mb-2 inline-flex flex-col   items-center  justify-center gap-y-2">
              <div
                className={`flex size-[1.875rem] shrink-0 flex-col content-center items-center justify-center  rounded-full text-center text-heading-3 text-white ${currentStep === 'checkout' ? 'bg-secondary text-white ' : 'bg-neutral-400 text-neutral-400'}`}
              >
                1
              </div>
              <div
                className={`whitespace-nowrap text-center text-body font-normal  ${currentStep === 'checkout' ? 'text-secondary ' : 'text-neutral-400'}`}
              >
                Assentos e passageiros
              </div>
            </li>
            <li className="!mb-2 inline-flex   flex-col items-center gap-y-2">
              <div
                className={`flex size-[1.875rem] shrink-0 flex-col content-center items-center justify-center  rounded-full bg-neutral-400 text-center text-heading-3 text-white ${currentStep === 'payment' ? 'bg-secondary' : 'bg-neutral-400'}`}
              >
                2
              </div>
              <div
                className={`whitespace-nowrap text-center text-body font-normal  ${currentStep === 'payment' ? 'text-secondary' : 'text-neutral-400'}`}
              >
                Pagamento
              </div>
            </li>
            <li className="!mb-2  inline-flex   flex-col items-center   gap-y-2 ">
              <div
                className={`flex size-[1.875rem] shrink-0 flex-col content-center items-center justify-center  rounded-full bg-neutral-400 text-center text-heading-3 text-white ${currentStep === 'confirmation' ? 'bg-secondary' : 'bg-neutral-400'}`}
              >
                3
              </div>
              <div
                className={`whitespace-nowrap text-center text-body font-normal  ${currentStep === 'confirmation' ? 'text-secondary' : 'text-neutral-400'}`}
              >
                Confirmação
              </div>
            </li>
            {/* End Item */}
          </ul>
          {/* End Stepper */}
        </div>
      </div>
      <div className="flex w-full flex-row gap-4 p-4 sm:flex-nowrap">
        <Outlet />
        <div className="  flex h-auto w-full max-w-[361px]  shrink-0 grow-0 flex-col gap-y-3 self-start sm:inline-flex sm:w-4/12">
          <CreditAllocationInfo
            vertical
            showAllotments={useAllotments}
            showCredit={useCredits}
          />
          <FlightDetails />
          {currentStep === 'checkout' && <FlightPriceDetail />}
        </div>
      </div>
      {modal.createTravelerVisible && <ModalReservationCreatePassenger />}
      <ModalReservationConfirm />
    </>
  );
}
