import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Form } from '@components/new-components/form';
import { InputV2 } from '@components/new-components/input-v2';
import { yupResolver } from '@hookform/resolvers/yup';
import { toaster } from '@system/utils/toaster';
import { PagePath } from '../pages-config';
import { useAuthentication } from '../stores/authentication';

const schemaValidation = yup.object().shape({
  email: yup.string().label('E-mail').required().email(),
  password: yup.string().label('Senha').required(),
});
const Login = () => {
  const navigate = useNavigate();
  const { loading, methods } = useAuthentication();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaValidation),
    mode: 'all',
  });

  const onSubmit = async (formData) => {
    try {
      if (await methods.signIn(formData)) {
        navigate(PagePath.Home);
      }
    } catch (err) {
      toaster('error', err);
    }
  };

  return (
    <div id="login">
      <div className="col01">
        <div className="bg_01"></div>
      </div>

      <div className="col02">
        <div className="formLogin">
          <div className="book2bLogin" alt="book2b" />

          <Form className="inputFormLogin" onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="pb-2">
              <Form.Label>Login</Form.Label>
              <InputV2
                {...register('email')}
                type="text"
                placeholder="Digite seu e-mail"
              />
              <Form.Message error={errors?.email} />
            </Form.Group>

            <Form.Group className="pb-2">
              <Form.Label>Senha</Form.Label>
              <InputV2
                {...register('password')}
                type="password"
                placeholder="Digite sua Senha"
              />
              <Form.Message error={errors?.password} />
            </Form.Group>

            <Link to={PagePath.RecuperarSenha} relative="path">
              Esqueceu a senha?
            </Link>

            <button type="submit">
              <span className={loading ? 'hide' : ''}>Entrar</span>
              <span className={loading ? 'loader' : 'hide'}></span>
            </button>

            <div className="mt-2 text-center">
              <Link to={PagePath.NovoUsario} relative="path">
                <small>Quer saber mais sobre a plataforma?</small>
              </Link>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default Login;
