import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { InputText } from '@components/input/InputText';

const DropdownStatus = {
  Invisible: 'invisible',
  FewCharacters: 'few-characters',
  Visible: 'visible',
};

export const InputTextDropdownItem = ({ children, onClick }) => (
  <li
    className="cursor-pointer bg-white p-3.5 px-5 py-3.5 text-sm hover:bg-neutral-100"
    onClick={onClick}
  >
    {children}
  </li>
);

export const InputTextDropdown = forwardRef(function InputTextDropdown(
  {
    children,
    dropdownTitle,
    dropdownIcon: Icon,
    minLength = 3,
    onChange,
    onDropdownAppears,
    onDropdownDisappears,
    ...props
  },
  ref,
) {
  const dropdownRef = useRef(null);
  const [dropdownStatus, setDropdownStatus] = useState(
    DropdownStatus.Invisible,
  );
  const isDropdownVisible =
    dropdownStatus === DropdownStatus.Visible ||
    dropdownStatus === DropdownStatus.FewCharacters;
  const dropdownOpacityClass = isDropdownVisible
    ? 'visible opacity-100'
    : 'invisible opacity-0';
  const isDropdownTitleVisible =
    dropdownTitle && dropdownStatus === DropdownStatus.Visible;

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        isDropdownVisible &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target)
      ) {
        setDropdownStatus(DropdownStatus.Invisible);
        onDropdownDisappears();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownVisible, onDropdownDisappears]);

  const debouncedHandleDropdownVisibility = useMemo(
    () =>
      debounce((e) => {
        if (e.target.value?.length >= minLength) {
          setDropdownStatus(DropdownStatus.Visible);
          onDropdownAppears(e);
        } else if (e.target.value?.length > 0) {
          setDropdownStatus(DropdownStatus.FewCharacters);
        } else {
          setDropdownStatus(DropdownStatus.Invisible);
          onDropdownDisappears();
        }
      }, 1000),
    [minLength], // eslint-disable-line react-hooks/exhaustive-deps
  );

  function handleTextChange(e) {
    debouncedHandleDropdownVisibility(e);
    onChange(e);
  }

  return (
    <div className="relative w-full">
      <InputText {...props} onChange={handleTextChange} ref={ref} />
      <ul
        className={`
        absolute left-0 top-[76px] z-10 w-full list-none divide-x-0
        divide-y divide-solid divide-neutral-300 overflow-hidden rounded-md
        border border-solid border-neutral-400 p-0
        shadow transition-all duration-500 ease-in-out
        ${dropdownOpacityClass}
        `}
        ref={dropdownRef}
        aria-hidden={!isDropdownVisible}
        onClick={() => setDropdownStatus(DropdownStatus.Invisible)}
      >
        {isDropdownTitleVisible && (
          <li className="bg-neutral-100 px-5 py-3.5 text-sm font-semibold text-neutral-800">
            {Icon && <Icon size={20} className="mr-2" />}
            <span>{dropdownTitle}</span>
          </li>
        )}
        {dropdownStatus === DropdownStatus.FewCharacters && (
          <li className="bg-neutral-100 px-5 py-3.5 text-sm font-semibold text-neutral-800">
            <span>Digite pelo menos {minLength} caracteres</span>
          </li>
        )}
        {dropdownStatus === DropdownStatus.Visible && children}
      </ul>
    </div>
  );
});
