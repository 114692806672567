import { useMemo, useState } from 'react';
import { BiSolidChevronRight } from 'react-icons/bi';
import { BsEyeFill } from 'react-icons/bs';

import { MdOutlineHome } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import LoadingAnimation from '../components/LoadingAnimation.jsx';
import { useAuthentication } from '@stores/authentication.js';
import { Role } from '@system/acl';
import { PagePath } from '../pages-config.js';

export default function Relatorios() {
  const navigate = useNavigate();
  const { session } = useAuthentication();
  const [loading] = useState(false);
  const [typeAlert] = useState('');
  const [titleAlert] = useState('');
  const [masterTitle] = useState('Relatórios');
  const [txtAlert] = useState('');
  const reportSelector = useMemo(
    () => ({
      development: {
        finance: 37,
        buyer: 36,
      },
      staging: {
        finance: 37,
        buyer: 36,
      },
      production: {
        finance: 35,
        buyer: 34,
      },
      demo: {
        finance: 39,
        buyer: 38,
      },
    }),
    [],
  );

  const dashboardsConfig = useMemo(() => {
    return !session?.role
      ? []
      : [
          {
            id: [Role.Administrador, Role.Gestao, Role.Financeiro].includes(
              session.role,
            )
              ? reportSelector[import.meta.env.VITE_REPORT_ENV].finance
              : reportSelector[import.meta.env.VITE_REPORT_ENV].buyer,
            type: 'dashboard',
            name: 'Reservas de Fretamento',
            params: [Role.Administrador, Role.Gestao, Role.Financeiro].includes(
              session.role,
            )
              ? {}
              : { contratante: session.agency.id },
          },
        ];
  }, [session, reportSelector]);
  const seeReport = (report) => {
    const { params, ...config } = report;
    const configQuery = new URLSearchParams(config).toString();
    const paramsQuery = new URLSearchParams(params).toString();
    const goTo = `${PagePath.Relatorio}?${configQuery}${paramsQuery ? '&' + paramsQuery : ''}`;
    navigate(goTo);
  };

  return (
    <>
      {loading && <LoadingAnimation />}
      <div className={typeAlert ? 'alert ' + typeAlert : 'hide'} role="alert">
        <h4 className={titleAlert ? '' : 'hide'}>{titleAlert}</h4>
        {txtAlert}
      </div>
      <div className="breadCrumb mx-auto my-0">
        <MdOutlineHome className="icoBread" />
        <BiSolidChevronRight className="caretRight" /> &nbsp;&nbsp;
        {masterTitle}
      </div>

      <div className="lineButtons row ">
        <div className="col">
          <h1>{masterTitle}</h1>
        </div>
      </div>

      <div className="contentAll">
        <div className="row">
          <div className="table_list">
            <table className="tblDefault">
              <thead>
                <tr>
                  <th scope="col">Nome</th>

                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {dashboardsConfig.map((data) => (
                  <tr key={data.name} onClick={() => seeReport(data)}>
                    <td>{data.name}</td>
                    <td>
                      <BsEyeFill />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
