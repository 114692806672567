import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { startOfToday, format, addDays } from 'date-fns';
import { produce } from 'immer';
import { unMask } from 'remask';
import { Button } from '@components/button';
import { CheckoutConfirmationBilling } from '@components/checkout/confirmation/billing';
import { CheckoutConfirmationCreditCard } from '@components/checkout/confirmation/credit-card';
import LoadingAnimation from '@components/LoadingAnimation';
import { Form } from '@components/new-components/form';
import { useAuthentication } from '@stores/authentication';
import { useBookings } from '@stores/bookings';
import { convertTravelerType } from '@system/converters/convertTravelerType';
import { BookingType, MetaSource, PaymentMethod } from '@system/enums';
import { formatCurrency } from '@system/utils';
import { toaster } from '@system/utils/toaster';

export default function AereoReservaConfirmar() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { session } = useAuthentication();
  const { travelers, methods, booking } = useBookings();
  const { state: params } = useLocation();
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [showError, setShowError] = useState(false);
  const { subtotal } = methods.getSummaryPrices();

  const handleAcceptTerms = () => {
    if (!acceptTerms) setShowError(false);
    setAcceptTerms(!acceptTerms);
  };

  const createBooking = async () => {
    try {
      if (!acceptTerms) {
        setShowError(true);
        return;
      }
      setLoading(true);
      // Adiciona um dia à data de hoje e define a hora para 00:00:00
      const dueAt = addDays(startOfToday(new Date()), 1);
      const paymentMethod = booking.paymentMethod;
      let newBooking = {
        value: subtotal,
        type: BookingType.AR,
        metaSource: MetaSource.Platform,
        agencyId: Number(session.agency.id),
        charterId: params.charterId,
        dueAt: format(dueAt, 'yyyy-MM-dd HH:mm:ss'),
        paymentMethod: booking.paymentMethod,
        travelers: travelers.map(
          ({ birthdate, document, category, name, locator, ...traveler }) => {
            return {
              ...traveler,
              locator,
              firstName: name,
              seat: traveler?.seat?.join('') ?? '',
              value: Number(traveler.value),
              class: category ?? '',
              gender: traveler.gender.substring(0, 1),
              birthDate: format(birthdate, 'dd/MM/yyyy'),
              cpf: unMask(document),
            };
          },
        ),
      };
      if (booking.paymentMethod === PaymentMethod['Cartão de Crédito']) {
        newBooking = {
          ...newBooking,
          installment: booking.installment,
          payerEmail: booking.payerEmail,
          brandName: booking.brandName,
        };
      }

      await methods.createBooking(newBooking);
      useBookings.setState(
        produce((draft) => {
          draft.booking.paymentMethod = paymentMethod;
        }),
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toaster('error', 'Não foi possível concluir a reserva');
    }
  };

  return (
    <>
      {loading && <LoadingAnimation />}
      <div className="flex w-full flex-col">
        <div className="flex w-full flex-col rounded-lg bg-white   sm:p-4">
          <div className=" flex w-full flex-col ">
            <span className="text-heading-2 text-primary">
              Os dados estão corretos?
            </span>
            <span className="mt-3 text-body font-normal">
              Confirme os dados e conclua a sua reserva. Caso precise alterar
              algum dado, retorne para a etapa necessária.
            </span>
          </div>
          <div className="mt-4  w-full items-center rounded-md border border-solid border-neutral-300 p-3 px-4">
            <div className="mt-2 w-full  border-0 !border-b border-solid border-neutral-300 pb-3 text-heading-3 font-semibold text-primary ">
              Passageiros a confirmar{' '}
              {travelers?.length > 0 ? `(${travelers.length})` : ''}
            </div>
            <div className="mt flex flex-col gap-y-4 ">
              <ul className="m-0 flex list-none flex-col gap-y-3 p-0">
                {(travelers ?? []).map((traveler) => (
                  <li
                    key={traveler?.id}
                    className="flex flex-row items-center justify-between gap-4  border-0 border-b border-solid border-neutral-300 px-3 py-6"
                  >
                    <div className="flex h-[35px] w-[35px] flex-col items-center justify-center  self-start rounded-lg bg-primary p-[10px] text-center text-white">
                      {traveler?.seat ?? '-'}
                    </div>
                    <div className="flex w-full flex-col">
                      <span className="text-small font-bold text-primary ">
                        {convertTravelerType(traveler?.type)}
                      </span>
                      <span className="text-small font-normal">
                        {[traveler?.name, traveler?.lastName].join(' ')}
                      </span>
                    </div>
                    <div className="whitespace-nowrap text-right text-body font-bold text-price">
                      {formatCurrency(traveler?.value ?? 0, true)}
                    </div>
                  </li>
                ))}
              </ul>
              <div className="w-full"></div>
            </div>
          </div>
          {booking.paymentMethod === PaymentMethod.Faturamento && (
            <CheckoutConfirmationBilling />
          )}
          {booking.paymentMethod === PaymentMethod['Cartão de Crédito'] && (
            <CheckoutConfirmationCreditCard />
          )}

          <div className="mt-4">
            <span className="flex items-center gap-2 text-body font-normal">
              <input
                type="checkbox"
                className="size-5 "
                checked={acceptTerms}
                onChange={handleAcceptTerms}
              />
              <span className="text-neutral-800">
                Li e aceito os{' '}
                <Link
                  to="https://book2b-prod.s3.amazonaws.com/docs/Política+de+cancelamento.pdf"
                  target="_blank"
                >
                  Termos e Condições
                </Link>{' '}
                de compra.
              </span>
            </span>
          </div>

          {showError && (
            <div className="mt-4">
              <Form.Message
                error={{
                  message:
                    'Para prosseguir, leia e aceite os termos e condições de compra',
                }}
              />
            </div>
          )}
        </div>
        <div className="mt-4 flex w-full flex-col ">
          <div className="flex flex-row justify-between">
            <Button
              label="Voltar"
              type="button"
              variant="ghost"
              className="w-2/12"
              onClick={() => {
                navigate(-1);
              }}
            />
            <Button
              label="Reservar"
              type="button"
              className="w-2/12"
              onClick={createBooking}
            />
          </div>
        </div>
      </div>
    </>
  );
}
