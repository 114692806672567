import { format, toZonedTime } from 'date-fns-tz';
import { ptBR } from 'date-fns/locale/pt-BR';

const BRTimeZone = 'America/Sao_Paulo';

/**
 * @param {Date} date
 */
export const toBRTimeZone = (date) => {
  return toZonedTime(date, 'America/Sao_Paulo');
};

/**
 * @param {Date} date
 * @param {string} formatStr
 */
export const formatToBRTz = (date, formatStr) => {
  const text = format(date, formatStr, {
    timeZone: BRTimeZone,
    locale: ptBR,
  });
  return text.charAt(0).toUpperCase() + text.slice(1);
};
